import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import { createRoot } from "react-dom/client";
import "./assets/scss/style.scss";
import App from "./app";
import { AuthProvider } from "./Authentication/AuthContext";
import { TicketProvider } from "./service/components/checkout/TicketProvider/TicketContext";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <AuthProvider>
    <TicketProvider>
      <App />
    </TicketProvider>
  </AuthProvider>
);

// Register the service worker from the public directory
if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("/service-worker.js")
      .then((registration) => {
        console.log(
          "Service Worker registered with scope:",
          registration.scope
        );
      })
      .catch((error) => {
        console.error("Service Worker registration failed:", error);
      });
  });
}
