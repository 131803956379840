import React, { createContext, useContext, useEffect, useState } from "react";
import { auth } from "../firebase";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getApp } from "firebase/app";

export const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [currentRole, setCurrentRole] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setCurrentUser(user.uid);
        const role = await fetchUserRole();
        setCurrentRole(role);
      } else {
        setCurrentUser(null);
        setCurrentRole("");
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const fetchUserRole = async () => {
    const checkAdminStatus = httpsCallable(
      getFunctions(getApp(), "europe-west1"),
      "checkAdminStatus"
    );

    try {
      const result = await checkAdminStatus();
      return result.data.role;
    } catch (error) {
      console.error("Error fetching user role:", error);
      return "";
    }
  };

  const value = {
    currentUser,
    currentRole,
    loading,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
