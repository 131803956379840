import React, { useState, Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useAuth } from "./Authentication/AuthContext";
import Spinner from "./global-utils/loading-spinner/spinner";

const Home = lazy(() => import("./pages/Home"));
const About = lazy(() => import("./pages/About"));
const Services = lazy(() => import("./service/Services"));
const PrivateBus = lazy(() => import("./service/PrivateBus"));
const BusTrips = lazy(() => import("./service/BusTrips"));
const PackageDelivery = lazy(() => import("./service/PackageDelivery"));
const Taxi = lazy(() => import("./service/Taxi"));
const Profile = lazy(() => import("./profile/profile"));
const Contact = lazy(() => import("./pages/Contact"));
const NoMatch = lazy(() => import("./pages/404"));
const SignIn = lazy(() => import("./Authentication/SignIn"));
const SignUp = lazy(() => import("./Authentication/SignUp"));
const AdminRoutes = lazy(() => import("./admin/AdminRoutes"));
const MapPage = lazy(() => import("./service/Tracking/trackMap"));
const Success = lazy(() =>
  import("./service/components/checkout/checkout-result/Success")
);
const Failure = lazy(() =>
  import("./service/components/checkout/checkout-result/Failure")
);
const Checkout = lazy(() => import("./service/components/checkout/Checkout"));

const App = () => {
  const { currentUser, currentRole } = useAuth();
  const orderIdFromStorage = sessionStorage.getItem("orderId");

  const SuccessRoute = ({ isRedirected }) => {
    if (!isRedirected) {
      return <Success />;
    } else {
      return <Navigate to="/home" replace />;
    }
  };

  return (
    <Router>
      <Suspense fallback={<Spinner />}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/private-bus" element={<PrivateBus />} />
          <Route path="/bus-trips" element={<BusTrips />} />
          {currentUser && <Route path="/reserve" element={<Checkout />} />}
          <Route path="/taxi" element={<Taxi />} />
          <Route path="/package-delivery" element={<PackageDelivery />} />
          {/* <Route path="/MapPage" element={<MapPage />} /> */}
          <Route path="/profile" element={<Profile />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/SignIn" element={<SignIn />} />
          <Route path="/SignUp" element={<SignUp />} />
          {orderIdFromStorage && (
            <Route
              path="/success"
              element={<SuccessRoute isRedirected={false} />}
            />
          )}

          {orderIdFromStorage && (
            <Route path="/failure" element={<Failure />} />
          )}

          {currentRole === "admin" && (
            <Route path="/admin/*" element={<AdminRoutes />} />
          )}
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
