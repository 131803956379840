import React, { createContext, useState } from "react";

// Create the context
const TicketContext = createContext();

// Create a provider component
const TicketProvider = ({ children }) => {
  const [ticketDetails, setTicketDetails] = useState({
    route: null,
    pickupLocation: null,
    secondaryPickupLocation: null,
    destination: null,
    date: null,
    time: null,
    price: null,
  });

  return (
    <TicketContext.Provider value={{ ticketDetails, setTicketDetails }}>
      {children}
    </TicketContext.Provider>
  );
};

export { TicketContext, TicketProvider };
